<template>
  <section class="admin-user-profile scroll-content">
    <h3 class="md-display-1">{{ $translate("user.profile") }}</h3>

    <div class="admin-user-profile__actions">
      <md-field>
        <label for="name">{{ $translate("user.inputs.name") }}</label>
        <md-input name="name" id="name" autocomplete="off" v-model="changed_name" type="text"/>
      </md-field>

      <div>
        {{ $translate("user.inputs.phone") }}
        <phone_number_input v-model="unformatted_phone" @update="update_phone_number" :default-country-code="changed_phone_country_code"/>
      </div>

      <md-field>
        <label for="country">{{ $translate("user.inputs.country") }}</label>
        <md-select v-model="changed_country" name="country" id="country" :placeholder="$translate('user.inputs.country')">
          <md-option
            v-for="{ name, code } in countries"
            :key="`country-${code}`"
            :value="name"
          >{{ name }}</md-option>
        </md-select>
      </md-field>

      <md-field>
        <label for="language">{{ $translate("user.inputs.language") }}</label>
        <md-select v-model="changed_language" name="language" id="language" :placeholder="$translate('user.inputs.language')">
          <md-option
            v-for="language_code in translated_languages"
            :key="`language-${language_code}`"
            :value="language_code"
          >
            {{ $translate(`languages.${language_code}`) }}
          </md-option>
        </md-select>
      </md-field>

      <md-field>
        <label for="currency">{{ $translate("user.inputs.currency") }}</label>
        <md-select v-model="changed_currency" name="currency" id="currency" :placeholder="$translate('user.inputs.currency')">
          <md-option
            v-for="currency in allowed_currencies"
            :key="`currency-code-${currency}`"
            :value="currency"
          >
            {{ currency }}
          </md-option>
        </md-select>
      </md-field>

      <md-button class="md-raised md-primary" @click="update_info">
        {{ $translate("save") }}
      </md-button>
    </div>
  </section>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex"
import countries from "../../../../../data/countries"
import phone_number_input from "vue-phone-number-input";
import { allowed_currencies, translated_languages } from "../../../../Shared/constants/other"
import { update_user_info } from "../../../constants/endpoints/firebase"
import { ADMIN_STORE, severities, USER_STORE } from "../../../constants/others_constants"
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import { ADD_GLOBAL_ERROR, FETCH_USER_CURRENCY_RATES, UPDATE_LOADER } from "../../../stores/Admin/constants"
import { SET_USER_INFO } from "../../../stores/Admin/user/constants"
import { update_admin_language } from "../../../../../data/other_constants"
import { event_hub } from "../../../../../main";

export default {
  components: {
    phone_number_input
  },
  data() {
    return {
      changed_name: "",
      changed_phone: "",
      unformatted_phone: "",
      changed_country: null,
      changed_language: null,
      changed_currency: null,
      changed_phone_country_code: null,
      countries,
      translated_languages,
      allowed_currencies
    }
  },
  computed: {
    ...mapState(USER_STORE, ["user_info"]),
  },
  mounted() {
    this.changed_name = this.user_info.name
    this.changed_phone = this.user_info.phone_number
    this.unformatted_phone = this.user_info.phone_number
    this.changed_country = this.user_info.country
    this.changed_phone_country_code = this.user_info.phone_country_code
    this.changed_language = this.user_info.language_code
    this.changed_currency = this.user_info.currency
  },
  methods: {
    ...mapMutations(USER_STORE, {
      set_user_info: SET_USER_INFO
    }),
    ...mapMutations(ADMIN_STORE, {
      add_global_error: ADD_GLOBAL_ERROR,
      update_loader: UPDATE_LOADER
    }),
    ...mapActions(ADMIN_STORE, {
      fetch_currency_rates: FETCH_USER_CURRENCY_RATES
    }),
    update_phone_number({ formattedNumber, countryCode }) {
      this.changed_phone_country_code = countryCode
      this.changed_phone = formattedNumber
    },
    async update_info() {
      if (!this.changed_name) {
        this.add_global_error({
          message: this.$translate("user.messages.fill_out_value", { input: this.$translate("user.labels.name") }),
          severity: severities.WARNING
        })

        return
      }
      this.update_loader()
      const update_currency_rates = this.changed_currency !== this.user_info.currency

      try {
        const language_updated = this.changed_language !== this.user_info.language_code
        const { data: updated_info } = await update_user_info({
          user_info: {
            name: this.changed_name,
            phone_number: this.changed_phone,
            country: this.changed_country,
            language_code: this.changed_language,
            currency: this.changed_currency,
            email: this.user_info.email,
            phone_country_code: this.changed_phone_country_code
          },
          id: this.user_info.id
        })

        this.set_user_info({
          ...updated_info,
          id: this.user_info.id
        })

        if (update_currency_rates) await this.fetch_currency_rates(this.changed_currency)
        if (language_updated) event_hub.$emit(update_admin_language, this.changed_language)

        setTimeout(() => this.add_global_error({
          message: this.$translate("user.messages.user_info_updated"),
          severity: severities.SUCCESS
        }), 200);
      } catch ({ message }) {
        this.add_global_error({ message })
      }

      this.update_loader(false)
    }
  }
}
</script>

<style lang="scss" scoped>
  .admin-user-profile {
    &__actions {
      width: 100%;
      max-width: 400px;
    }
  }
</style>

<style>
  .vue-phone-number-input {
    position: relative;
    z-index: 4;
  }
</style>
