<template>
  <section class="user-settings scroll-content">
    <h3 class="md-display-1">{{ $translate("user.settings") }}</h3>

    <div class="user-settings__actions">
      <div>
        <p class="md-headline">{{ $translate("user.update_email") }}</p>
        <md-field :class="{ 'md-invalid': !is_email_valid }">
          <label>{{ $translate("user.inputs.email") }}</label>
          <md-input type="email" v-model="user_email"/>
          <span class="md-error">{{ $translate("user.messages.invalid_email") }}</span>
        </md-field>

        <md-button class="md-raised md-primary" :disabled="!can_user_change_email" @click="can_user_change_email && update_email()">
          {{ $translate("user.update_email") }}
        </md-button>
      </div>
      <br>
      <div>
        <p class="md-headline">{{ $translate("user.change_password") }}</p>
        <md-field>
          <label>{{ $translate("user.inputs.password") }}</label>
          <md-input
            autocomplete="new-password"
            type="password"
            v-model="new_password"
          />
        </md-field>

        <md-field :class="{ 'md-invalid': !passwords_match }">
          <label>{{ $translate("user.inputs.password_again") }}</label>
          <md-input
            autocomplete="new-password"
            type="password"
            v-model="new_password_again"
          />
          <span class="md-error">{{ $translate("user.messages.passwords_unmatched") }}</span>
        </md-field>

        <md-button class="md-raised md-primary" :disabled="!can_user_change_password" @click="can_user_change_password && update_password()">
          {{ $translate("user.change_password") }}
        </md-button>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex"
import { ADMIN_STORE, severities, USER_STORE } from "../../../constants/others_constants"
import { ADD_GLOBAL_ERROR, LOGIN_VIA_TOKEN, UPDATE_LOADER } from "../../../stores/Admin/constants"
import email_validator from "../../../methods/email_validator"
import { update_user_info } from "../../../constants/endpoints/firebase"
import { SET_USER_INFO } from "../../../stores/Admin/user/constants"

export default {
  data() {
    return {
      new_password: "",
      new_password_again: "",
      new_email: "",
    }
  },
  computed: {
    ...mapState(USER_STORE, ["permissions", "user_info"]),
    passwords_match() {
      return this.new_password_again === this.new_password || !this.new_password
    },
    user_email: {
      get() { return this.new_email || this.user_info.email },
      set(email) { this.new_email = email }
    },
    is_email_valid() {
      return email_validator(this.user_email)
    },
    can_user_change_email() {
      return this.permissions.user.sections.settings.email && this.is_email_valid && this.user_email !== this.user_info.email
    },
    can_user_change_password() {
      return this.new_password_again && this.new_password && this.passwords_match && this.permissions.user.sections.settings.password
    },
  },
  created() {
    if (!this.permissions.user.sections.settings.module) this.$router.push("/admin")
  },
  methods: {
    ...mapMutations(ADMIN_STORE, {
      add_global_error: ADD_GLOBAL_ERROR,
      update_loader: UPDATE_LOADER
    }),
    ...mapMutations(USER_STORE, {
      update_user_info: SET_USER_INFO,
    }),
    ...mapActions(ADMIN_STORE, {
      login_user: LOGIN_VIA_TOKEN
    }),
    get_current_user() {
      return window.firebase.auth().currentUser
    },
    async update_email() {
      this.update_loader()
      try {
        // Verify that user has active session
        await this.login_user()
        await this.get_current_user().updateEmail(this.new_email)

        const { data } = await update_user_info({
          user_info: {
            country: this.user_info.country,
            email: this.new_email,
            language_code: this.user_info.language_code,
            name: this.user_info.name,
            uid: this.user_info.id,
            phone_number: this.user_info.phone_number
          },
          id: this.user_info.id
        })
        this.update_user_info({
          ...data,
          id: this.user_info.id
        })

        this.add_global_error({
          message: this.$translate("user.messages.email_updated"),
          severity: severities.SUCCESS
        })
      } catch ({ message }) {
        this.add_global_error({ message })
      }
      this.update_loader(false)
    },
    async update_password() {
      this.update_loader()
      try {
        await this.get_current_user().updatePassword(this.new_password)

        this.new_password = ""
        this.new_password_again = ""
        this.add_global_error({
          message: this.$translate("user.messages.password_changed"),
          severity: severities.SUCCESS
        })
      } catch ({ message }) {
        this.add_global_error({ message })
      }
      this.update_loader(false)
    }
  }
}
</script>

<style lang="scss" scoped>
  .user-settings {
    &__actions {
      width: 100%;
      max-width: 400px;
    }
  }
</style>
