<template>
  <section class="support scroll-content">
    <h3 class="md-display-1">{{ $translate("user.support") }}</h3>

    <div class="support__actions">
      <p class="md-subheading">{{ $translate("user.send_message") }}:</p>

      <md-field>
        <label for="subject">{{ $translate("user.support_subjects.title") }}</label>
        <md-select v-model="chosen_subject" name="subject" id="subject">
          <md-option
            v-for="(subject, index) in subjects"
            :key="`subject-${index}`"
            :value="subject"
          >{{ subject }}</md-option>
        </md-select>
      </md-field>

      <md-field>
        <label>{{ $translate("user.inputs.message") }}</label>
        <md-textarea v-model="message"  maxlength="500" md-counter="500"/>
      </md-field>

      <p class="support__error" v-if="validation_message">{{ validation_message }}</p>

      <md-button class="md-raised md-primary" @click="sendMessage">
        {{ $translate("send") }}
      </md-button>
    </div>
  </section>
</template>

<script>
import { mapMutations, mapState } from "vuex"
import { send_support_message } from "../../../constants/endpoints/firebase"
import { ADMIN, severities, USER_STORE } from "../../../constants/others_constants"
import { ADD_GLOBAL_ERROR, UPDATE_LOADER } from "../../../stores/Admin/constants"

export default {
  data() {
    return {
      subjects: [
        this.$translate("user.support_subjects.feature_request"),
        this.$translate("user.support_subjects.bug_report"),
        this.$translate("user.support_subjects.general_question")
      ],
      chosen_subject: "",
      validation_message: "",
      message: ""
    };
  },
  computed: {
    ...mapState(USER_STORE, ["user_info"]),
  },
  methods: {
    ...mapMutations(ADMIN, {
      add_global_error: ADD_GLOBAL_ERROR,
      update_loader: UPDATE_LOADER
    }),
    validate() {
      if (!this.chosen_subject) this.validation_message = this.subjectValidation = this.$translate("user.support_errors.subject_missing")
      else if (!this.message) this.validation_message = this.$translate("user.support_errors.empty_message")
      else if (this.message.length >= 500) this.validation_message = this.$translate("user.support_errors.max_length")
      else this.validation_message = ""
    },
    async sendMessage() {
      this.validate()

      if (this.validation_message) return

      this.update_loader(true)
      try {
        await send_support_message({
          to: "info@ulsemo.com",
          from: this.user_info.email,
          subject: `Admin support message - ${this.chosen_subject}`,
          text: `
            name: ${this.user_info.name}
            id: ${this.user_info.id}
            email: ${this.user_info.email}
            ${this.message}
          `,
          html: `
            Name: ${this.user_info.name}<br>
            ID: ${this.user_info.id}<br>
            Email: <a href="mailto:${this.user_info.email}">${this.user_info.email}</a><br>
            Message:
            <p>${this.message}</p>
          `,
        })

        this.message = ""
        this.chosen_subject = ""

        this.add_global_error({
          message: this.$translate("user.messages.message_send"),
          severity: severities.SUCCESS
        })
      } catch ({ message }) {
        this.add_global_error({ message })
      }

      this.update_loader(false)
    }
  },
};
</script>

<style lang="scss" scoped>
  @use "../../../../../styles/_admin-constants" as *;
  @use "../../../../../styles/_global-constants" as *;

  .support {
    &__actions {
      width: 100%;
      max-width: 400px;
    }

    &__error {
      padding: 10px 20px;
      background-color: $material-red;
      border-radius: 2px;
      color: $pure-white;
    }
  }
</style>
