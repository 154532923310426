<template>
  <section class="billing scroll-content">
    <h3 class="md-display-1">{{ $translate("user.billing.title") }}</h3>
    <div class="md-body-1">{{ $translate("user.billing.payment_method_message") }}</div>
    <p v-if="!subscription_payed && is_still_free_trial" class="md-body-2" v-html="$translate('user.billing.subscription_message')" />

    <div
      class="billing__message"
      :class="{ 'md-success': subscription_payed, 'md-alert': !subscription_payed }"
    >
      <md-icon>
        {{ subscription_payed ? "done" : "error_outline" }}
      </md-icon>
      {{ $translate(`user.billing.statuses.${subscription_payed ? "subscribed" : "unsubscribed"}`) }}
    </div>
    <md-button v-if="!subscription_payed || is_still_free_trial" @click="add_payment_method" class="md-raised md-primary">
      {{ $translate("user.billing.payment_method_button_add") }}
    </md-button>
    <md-button v-else @click="update_payment_method" class="md-raised md-primary">
      {{ $translate("user.billing.payment_method_button_change") }}
    </md-button>
  </section>
</template>

<script>
import { mapMutations, mapState } from "vuex"
import { ADMIN_STORE, CONFIGURATION_STORE, severities, SWITCH_PROJECT, USER_STORE } from "../../../constants/others_constants"
import { ADD_GLOBAL_ERROR, UPDATE_LOADER } from "../../../stores/Admin/constants"
import { SHARED_STORE } from "../../../../Shared/constants/other"
import { delete_store_or_user } from "../../../constants/endpoints/firebase"
import { event_hub } from "../../../../../main"
import {
   create_billing_session, create_subscription_session
} from "../../../constants/endpoints/other"

export default {
  data() {
    return {
      delete_account: false,
      open_store_deletion_modal: false,
      store_name_input: "",
      date: new Date().toISOString().split("T")[0],
    }
  },
  computed: {
    ...mapState(CONFIGURATION_STORE, [
      "project_config", "rest_config", "project_name", "project_styles"
    ]),
    ...mapState(USER_STORE, ["permissions", "user_info", "is_any_other_store_admin", "paying_user"]),
    ...mapState(SHARED_STORE, ["is_global_admin"]),
    ...mapState(ADMIN_STORE, ["free_trial_end", "subscription_payed"]),
    can_user_delete_store() {
      return this.is_global_admin && this.permissions.admin
    },
    store_name() {
      return this.project_name || this.project_config?.domain || "my-store"
    },
    is_still_free_trial() {
      return Number(this.free_trial_end) > Number((new Date().getTime() / 1000).toFixed(0))
    }
  },
  created() {
    if (!this.permissions.admin) this.$router.push("/admin")
    if (
      this.$route.query?.stripe_session_canceled ||
      this.$route.query?.stripe_session_updated ||
      this.$route.query?.stripe_session_created
    ) {
      if (this.paying_user) this.add_global_error({
        message: this.$translate(`user.billing.statuses.${
          this.$route.query.stripe_session_canceled ? "canceled" :
            this.$route.query.stripe_session_updated ? "updated" : "created"
        }`),
        severity: severities.SUCCESS,
      })
      else this.add_global_error({
        message: this.$translate("user.billing.statuses.error"),
      })
    }
  },
  methods: {
    ...mapMutations(ADMIN_STORE, {
      add_global_error: ADD_GLOBAL_ERROR,
      update_loader: UPDATE_LOADER
    }),
    async add_payment_method() {
      this.update_loader()
      const { data: payment_url } = await create_subscription_session({
        redirect_url: window.location.origin + this.$route.path,
        trial_end: this.free_trial_end
      })
      window.location = payment_url
    },
    async update_payment_method() {
      this.update_loader()
      const { data: payment_update_url } = await create_billing_session({
        redirect_url: window.location.origin + this.$route.path
      })
      window.location = payment_update_url
    },
    async delete_store() {
      this.update_loader()
      await delete_store_or_user({ uid: this.delete_account && this.user_info.id})
      this.update_loader(false)
      this.delete_account ? window.location.reload() : event_hub.$emit(SWITCH_PROJECT)
    },
    toggle_store_deletion_modal() {
      this.open_store_deletion_modal = !this.open_store_deletion_modal
    }
  }
}
</script>

<style lang="scss">
  @use "../../../../../styles/_admin-constants" as *;
  @use "../../../../../styles/_global-constants" as *;

  .billing {
    &__message {
      width: 100%;
      max-width: 300px;
      margin: $default-size 0;
      border-radius: $border-radius;
      font-size: 15px;
      text-align: center;
      font-weight: bold;

      i {
        display: inline-block;
        height: 32px;
        width: 32px;
        vertical-align: middle;
        font-size: 32px !important;
        margin: $half-default-size $half-default-size $half-default-size 0;
        color: $pure-white !important;
      }
    }
  }
</style>
