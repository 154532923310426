<template>
  <section class="store-settings scroll-content">
    <h3 class="md-display-1">{{ $translate("user.store_settings.title") }}</h3>

    <div class="store-settings__actions">
      <p class="md-headline">{{ $translate("user.store_settings.delete_store") }}</p>
      <div class="md-body" v-html="$translate('user.store_settings.delete_store_message', { store_name: store_name, date })" />

      <md-button
        class="md-raised md-accent"
        :disabled="!can_user_delete_store"
        @click="can_user_delete_store && toggle_store_deletion_modal()"
      >
        {{ $translate("user.store_settings.delete_store") }}
      </md-button>
    </div>

    <md-dialog :md-active.sync="open_store_deletion_modal">
      <md-dialog-content class="store-settings__guide-modal">
        <p class="md-title">{{ $translate("user.store_settings.delete_store") }}</p>

        <div class="md-body">{{ $translate("user.store_settings.deletion_steps") }} <b>{{ store_name }}</b></div>
        <md-field>
          <label>{{ $translate("user.store_settings.deletion_label") }}</label>
          <md-input
            type="text"
            v-model="store_name_input"
          />
        </md-field>

        <md-checkbox v-model="delete_account" :disabled="is_any_other_store_admin">
          {{ $translate("user.store_settings.remove_user") }}
        </md-checkbox>
        <div class="md-caption">{{ $translate(`user.store_settings.${is_any_other_store_admin ? "remove_user_tooltip" : "remove_user_message"}`) }}</div>

        <br />

        <div class="store-settings__buttons">
          <md-button
            class="md-raised md-accent"
            :disabled="store_name_input !== store_name"
            @click="delete_store"
          >{{ $translate("delete") }}</md-button>
        </div>
      </md-dialog-content>

      <md-dialog-actions>
        <md-button
          class="md-raised md-primary"
          @click="toggle_store_deletion_modal"
        >{{ $translate("cancel") }}</md-button>
      </md-dialog-actions>
    </md-dialog>
  </section>
</template>

<script>
import { mapMutations, mapState } from "vuex"
import { ADMIN_STORE, CONFIGURATION_STORE, SWITCH_PROJECT, USER_STORE } from "../../../constants/others_constants"
import { ADD_GLOBAL_ERROR, UPDATE_LOADER } from "../../../stores/Admin/constants"
import { SHARED_STORE } from "../../../../Shared/constants/other"
import { delete_store_or_user } from "../../../constants/endpoints/firebase"
import { event_hub } from "../../../../../main"

export default {
  data() {
    return {
      delete_account: false,
      open_store_deletion_modal: false,
      store_name_input: "",
      date: new Date().toISOString().split('T')[0]
    }
  },
  computed: {
    ...mapState(CONFIGURATION_STORE, [
      "project_config", "rest_config", "project_name", "project_styles"
    ]),
    ...mapState(USER_STORE, ["permissions", "user_info", "is_any_other_store_admin"]),
    ...mapState(SHARED_STORE, ["is_global_admin"]),
    can_user_delete_store() {
      return this.is_global_admin && this.permissions.admin
    },
    store_name() {
      return this.project_name || this.project_config?.domain || "my-store"
    }
  },
  mounted() {
    if (!this.permissions.admin) this.$router.push(`/admin`)
  },
  methods: {
    ...mapMutations(ADMIN_STORE, {
      add_global_error: ADD_GLOBAL_ERROR,
      update_loader: UPDATE_LOADER
    }),
    async delete_store() {
      this.update_loader()
      await delete_store_or_user({ uid: this.delete_account && this.user_info.id})
      this.update_loader(false)
      this.delete_account ? window.location.reload() : event_hub.$emit(SWITCH_PROJECT)
    },
    toggle_store_deletion_modal() {
      this.open_store_deletion_modal = !this.open_store_deletion_modal
    }
  }
}
</script>

<style lang="scss">
  @use "../../../../../styles/_admin-constants" as *;

  .store-settings {
    &__actions {
      width: 100%;
      max-width: 600px;

      .md-button {
        margin-top: $default-size;
      }
    }

    &__buttons {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__guide-modal .md-checkbox {
      margin-bottom: 0 !important;
    }
  }
</style>
