<template>
  <section class="user-management scroll-content">
    <h3 class="md-display-1">{{ $translate("user.manage_users") }}</h3>

    <md-table
      v-model="users"
      class="md-elevation-3 user-management__users"
      md-card
    >
      <md-table-toolbar>
        <p class="md-subheading">{{ $translate("user.users_under_management") }}:</p>

        <md-button
          v-if="permissions.user.sections.permissions.manage"
          class="md-icon-button md-raised md-primary user-management__add"
          @click="add_user_init"
        >
          <md-icon>add</md-icon>
          <md-tooltip md-direction="top">{{ $translate("user.add_user") }}</md-tooltip>
        </md-button>
      </md-table-toolbar>

      <md-table-row
        slot="md-table-row"
        slot-scope="{ item }"
      >
        <md-table-cell :md-label="$translate('user.labels.name')">
          {{ item.user_info.name }}
        </md-table-cell>
        <md-table-cell :md-label="$translate('user.labels.email')">
          <span class="user-management__email">{{ item.user_info.email }}</span>
        </md-table-cell>
        <md-table-cell class="user-management__actions">
          <md-button
            v-if="permissions.user.sections.permissions.manage"
            class="md-icon-button md-raised md-primary user-management__user-action"
            @click="edit_user_init(item)"
          >
            <md-icon>edit</md-icon>
            <md-tooltip md-direction="top">{{ $translate("user.edit_user") }}</md-tooltip>
          </md-button>
          <md-button
            v-if="permissions.user.sections.permissions.manage"
            class="md-icon-button md-raised md-accent user-management__user-action"
            @click="delete_user_init(item)"
          >
            <md-icon>delete_outline</md-icon>
            <md-tooltip md-direction="top">{{ $translate("user.delete_user") }}</md-tooltip>
          </md-button>
        </md-table-cell>
      </md-table-row>
    </md-table>

    <md-dialog :md-active.sync="open_modal">
      <md-dialog-title>
        {{ adding_user ? $translate("user.add_user") : $translate("user.edit_user") }}
        <hr>
      </md-dialog-title>
      <div class="user-management__modal">
        <div class="user-management__content" :class="{ 'user-management__content--editing-user': !adding_user }">
          <div v-if="adding_user">
            <div class="md-subheading">{{ $translate("user.user_data") }}:</div>
            <md-field :class="{ 'md-invalid': show_same_email_error }">
              <label for="email">{{ $translate("user.inputs.email") }}</label>
              <md-input
                name="email"
                id="email"
                autocomplete="off"
                autocorrect="off"
                autocapitalize="none"
                spellcheck="false"
                v-model="selected_email"
                type="email"
              />
              <Loader v-if="loading_user" />
              <div v-if="found_user" class="user-management__found-user" @click="select_existing_user">
                <!-- TODO add user profile pictures -->
                <img src="/static/backgrounds/default-img.jpg" alt="user profile image">
                <div class="user-management__found-user__info">
                  <div>{{ found_user.user_info.name }}</div>
                  <div>{{ found_user.user_info.email }}</div>
                </div>
              </div>
              <span class="md-error">{{ $translate('user.messages.same_email_error') }}</span>
            </md-field>

            <md-field>
              <label for="name">{{ $translate("user.inputs.name") }}</label>
              <md-input name="name" id="name" autocomplete="off" v-model="selected_name" type="text" :disabled="!!(found_user || selected_existing_user)"/>
            </md-field>

            <phone_number_input v-model="phone_number_to_show" @update="update_phone_number" :disabled="!!(found_user || selected_existing_user)"/>

            <md-field>
              <label for="country">{{ $translate("user.inputs.country") }}</label>
              <md-select v-model="selected_country" name="country" id="country" :placeholder="$translate('user.inputs.country')" :disabled="!!(found_user || selected_existing_user)">
                <md-option
                  v-for="{ name, code } in countries"
                  :key="`country-${code}`"
                  :value="name"
                >{{ name }}</md-option>
              </md-select>
            </md-field>

            <md-field>
              <label for="language">{{ $translate("user.inputs.language") }}</label>
              <md-select v-model="selected_language" name="language" id="language" :placeholder="$translate('user.inputs.language')" :disabled="!!(found_user || selected_existing_user)">
                <md-option
                  v-for="language_code in translated_languages"
                  :key="`language-${language_code}`"
                  :value="language_code"
                >
                  {{ $translate(`languages.${language_code}`) }}
                </md-option>
              </md-select>
            </md-field>
          </div>

          <div :class="{ 'user-management__disabled-content': !can_edited_user_manage_users  }">
            <p class="md-subheading user-management__second-title">{{ $translate("user.users_under_management") }}:</p>

            <div v-if="users.length">
              <md-table v-model="users_without_edited_user" @md-selected="select_managed_user" md-fixed-header>
                <md-table-row slot="md-table-row" slot-scope="{ item }" md-selectable="multiple" md-auto-select :md-disabled="!can_edited_user_manage_users">
                  <md-table-cell :md-label="$translate('user.labels.name')">{{ item.user_info.name }}</md-table-cell>
                  <md-table-cell :md-label="$translate('user.labels.email')">{{ item.user_info.email }}</md-table-cell>
                </md-table-row>
              </md-table>
            </div>
            <div v-else>
              {{ $translate("user.no_users_created_yet") }}
            </div>
          </div>
        </div>

        <div
          class="user-management__content"
          :class="{ 'user-management__content--full-size': !adding_user }"
        >
          <p class="md-subheading">{{ $translate("user.user_permissions") }}:</p>
          <div class="user-management__permissions">
            <ul
              v-for="(base_permissions, base_key) in selected_permissions"
              :key="`permission-${base_key}`"
            >
              <li class="user-management__first-element" v-if="selected_permissions[base_key] !== undefined">
                <p><b>{{ $translate(`user.permissions.${base_key}`) }}</b></p>
                <div v-if="typeof base_permissions === 'object'">
                  <ul
                    v-for="(parent_value, parent_key) in base_permissions"
                    :key="`permission-${base_key}-${parent_key}`"
                  >
                    <div v-if="parent_key === 'sections'">
                      <div v-if="typeof parent_value === 'object'">
                        <ul
                          v-for="(child_value, child_key) in parent_value"
                          :key="`permission-${base_key}-${parent_key}-${child_key}`"
                        >
                          <li>
                            <div v-if="typeof parent_value === 'object'">
                              <p>{{ $translate(`user.permissions.${base_key}`) }} -> <b>{{ $translate(`user.permissions.${child_key}`) }}</b></p>
                              <md-checkbox
                                v-for="(final_value, final_key) in child_value"
                                :key="`permission-${base_key}-${parent_key}-${child_key}-${final_key}`"
                                v-model="selected_permissions[base_key][parent_key][child_key][final_key]"
                                class="md-primary user-management__checkbox"
                              >{{ $translate(`user.permissions.${final_key}`) }}</md-checkbox>
                            </div>
                            <md-checkbox
                              v-else
                              v-model="selected_permissions[base_key][parent_key]"
                              class="md-primary"
                            >{{ $translate(`user.permissions.${child_key}`) }}</md-checkbox>
                          </li>
                        </ul>
                      </div>
                      <md-checkbox
                        v-else
                        v-model="selected_permissions[base_key][parent_key]"
                        class="md-primary"
                      >{{ $translate(`user.permissions.${parent_key}`) }}</md-checkbox>
                    </div>
                    <li v-else>
                      <div v-if="typeof parent_value === 'object'">
                        <p>{{ $translate(`user.permissions.${base_key}`) }} -> <b>{{ $translate(`user.permissions.${parent_key}`) }}</b></p>
                        <ul
                          v-for="(child_value, child_key) in parent_value"
                          :key="`permission-${base_key}-${parent_key}-${child_key}`"
                        >
                          <li>
                            <div v-if="typeof parent_value === 'object'">
                              <p>{{ $translate(`user.permissions.${base_key}`) }} -> {{ $translate(`user.permissions.${parent_key}`) }} -> <b>{{ $translate(`user.permissions.${child_key}`) }}</b></p>
                              <md-checkbox
                                v-for="(final_value, final_key) in child_value"
                                :key="`permission-${base_key}-${parent_key}-${child_key}-${final_key}`"
                                v-model="selected_permissions[base_key][parent_key][child_key][final_key]"
                                class="md-primary user-management__checkbox"
                              >{{ $translate(`user.permissions.${final_key}`) }}</md-checkbox>
                            </div>
                            <md-checkbox
                              v-else
                              v-model="selected_permissions[base_key][parent_key]"
                              class="md-primary"
                            >{{ $translate(`user.permissions.${child_key}`) }}</md-checkbox>
                          </li>
                        </ul>
                      </div>
                      <md-checkbox
                        v-else
                        v-model="selected_permissions[base_key][parent_key]"
                        class="md-primary"
                      >{{ $translate(`user.permissions.${parent_key}`) }}</md-checkbox>
                    </li>
                  </ul>
                </div>
                <md-checkbox
                  v-else
                  v-model="selected_permissions[base_key]"
                  class="md-primary"
                >{{ $translate(`user.permissions.${base_key}`) }}</md-checkbox>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <md-dialog-actions>
        <md-button class="md-primary" @click="open_modal = false">{{ $translate("cancel") }}</md-button>
        <md-button
          :disabled="!can_user_save_data"
          class="md-raised md-primary"
          @click="adding_user ? add_user() : edit_user()"
        >{{ $translate("save") }}</md-button>
      </md-dialog-actions>
    </md-dialog>

    <md-dialog :md-active.sync="delete_user_modal">
      <md-dialog-title>{{ $translate('user.delete_user') }}</md-dialog-title>

      <md-dialog-content>
        <p>
          {{ $translate('user.messages.confirm_delete', { email: (active_user.user_info || {}).email }) }}
        </p>
      </md-dialog-content>

      <md-dialog-actions>
        <md-button class="md-primary" @click="cancel_delete">{{ $translate("cancel") }}</md-button>
        <md-button class="md-raised md-accent" @click="delete_user">{{ $translate("delete") }}</md-button>
      </md-dialog-actions>
    </md-dialog>
  </section>
</template>

<script>
import { mapMutations, mapState } from "vuex"
import countries from "../../../../../data/countries"
import Loader from "@/applications/Shared/components/Loader/index.vue"
import phone_number_input from "vue-phone-number-input";
import { message_durations, translated_languages } from "../../../../Shared/constants/other"
import {
  add_user_projects_and_permissions, assign_managed_users, create_new_user, delete_user,
  find_user_by_email, get_users_info, update_user_permissions_and_managed_users
} from "../../../constants/endpoints/firebase"
import { account_amounts_per_plan_type, ADMIN_STORE, severities, USER_STORE, empty_user_permissions } from "../../../constants/others_constants"
import email_validator from '../../../methods/email_validator'
import { ADD_GLOBAL_ERROR, UPDATE_LOADER } from "../../../stores/Admin/constants"
import { SET_MANAGED_USERS } from "../../../stores/Admin/user/constants"
import "vue-phone-number-input/dist/vue-phone-number-input.css";

const default_user_data = {
  delete_user_modal: false,
  selected_country: null,
  selected_language: null,
  selected_permissions: null,
  selected_email: "",
  selected_name: "",
  selected_phone_number: "",
  phone_number_to_show: "",
  selected_managed_users: [],
}

export default {
  components: {
    Loader,
    phone_number_input
  },
  data() {
    return {
      open_modal: false,
      adding_user: false,
      loading_user: false,
      show_same_email_error: false,
      found_user: null,
      selected_existing_user: null,
      active_user: {},
      users: [],
      countries,
      translated_languages,
      ...default_user_data
    }
  },
  computed: {
    ...mapState(USER_STORE, [
      "permissions",
      "user_info",
      "managed_users",
      "user_accounts",
      "plan_type"
    ]),
    ...mapState(ADMIN_STORE, ["feature_toggles"]),
    can_user_save_data() {
      return this.adding_user && !this.selected_existing_user ? (this.selected_country &&
        this.selected_language &&
        this.selected_email &&
        this.selected_name) : true
    },
    can_edited_user_manage_users() {
      return this.selected_permissions ?
        this.selected_permissions.user.sections.permissions.module &&
        this.selected_permissions.user.sections.permissions.manage :
        false
    },
    users_without_edited_user() {
      return this.users.filter(user => user.user_info.email !== this.active_user.user_info.email)
    },
  },
  watch: {
    open_modal(value) {
      if (value) this.selected_permissions = this.adding_user ? empty_user_permissions : this.active_user.permissions
    },
    selected_email(email) {
      if (email_validator(email)) this.find_user(email)
      else {
        this.found_user = null
        this.selected_existing_user = null
      }
    }
  },
  created() {
    if (!this.permissions.user.sections.permissions.module) this.$router.push("/admin")

    this.fetch_users()
  },
  methods: {
    ...mapMutations(USER_STORE, {
      set_managed_users: SET_MANAGED_USERS,
    }),
    ...mapMutations(ADMIN_STORE, {
      add_global_error: ADD_GLOBAL_ERROR,
      update_loader: UPDATE_LOADER
    }),
    update_phone_number({ formatNational, formattedNumber }) {
      this.phone_number_to_show = formatNational
      this.selected_phone_number = formattedNumber
    },
    select_existing_user() {
      this.selected_existing_user = this.found_user
      this.selected_country = this.found_user.user_info.country
      this.selected_language = this.found_user.user_info.language_code
      this.selected_email = this.found_user.user_info.email
      this.selected_name = this.found_user.user_info.name
      this.selected_phone_number = this.found_user.user_info.phone_number
      this.phone_number_to_show = this.found_user.user_info.phone_number
      this.found_user = null
    },
    async find_user(email) {
      if (this.user_info.email === this.selected_email) return this.show_same_email_error = true
      else this.show_same_email_error = false

      this.loading_user = true
      try {
        const { data: user } = await find_user_by_email(email)

        if (user) this.found_user = user
      } catch (error) {
        this.found_user = null
        this.selected_existing_user = null
      }
      this.loading_user = false
    },
    async fetch_users() {
      this.update_loader()

      try {
        const { data } = await get_users_info(this.managed_users, this.user_info.id)

        this.users = data
      } catch (error) {
        this.add_global_error(error)
      }

      this.update_loader(false)
    },
    add_user_init() {
      const user_limit = account_amounts_per_plan_type[this.plan_type || "full"]

      if (user_limit === this.user_accounts.length) {
        this.add_global_error({
          message: this.$translate("user.messages.users_over_limit", { user_limit }),
          severity: severities.WARNING,
          duration: message_durations.long
        })

        return
      }

      this.adding_user = true
      this.open_modal = true
    },
    async add_user() {
      if (this.user_info.email === this.selected_email) return this.show_same_email_error = true
      else this.show_same_email_error = false

      this.update_loader()

      try {
        const temporary_password = Math.random().toString(36).slice(-8)
        let user_id
        if (this.selected_existing_user) {
          await add_user_projects_and_permissions({
            user_id: this.selected_existing_user.id,
            permissions: this.selected_permissions,
            user: this.selected_existing_user
          })
          if (this.selected_managed_users.length) await assign_managed_users({
            user_id: this.selected_existing_user.id,
            managed_users: [...this.selected_existing_user.managed_users, ...this.selected_managed_users]
          })
          user_id = this.selected_existing_user.id
        } else {
          const { data } = await create_new_user({
            user_data: {
              user_info: {
                name: this.selected_name,
                email: this.selected_email,
                country: this.selected_country,
                language: this.selected_language,
                phone_number: this.selected_phone_number,
                password: temporary_password,
              },
              managed_users: this.selected_managed_users,
              permissions: this.selected_permissions
            }
          })
          user_id = data
        }

        if (!this.selected_existing_user) await firebase.auth().sendPasswordResetEmail(this.selected_email)

        const { data: managed_users } = await assign_managed_users({
          managed_users: [...this.managed_users, user_id],
          user_id: this.user_info.id
        })

        this.set_managed_users(managed_users)
        await this.fetch_users()

        Object.entries(default_user_data).forEach(([key, val]) => this[key] = val)
        this.add_global_error({
          message: this.selected_existing_user ? this.$translate("user.messages.user_added_to_project") : this.$translate("user.messages.new_user_created"),
          severity: severities.SUCCESS
        })
        this.adding_user = false
        this.open_modal = false
      } catch (error) {
        this.add_global_error(error)
      }

      this.update_loader(false)
    },
    edit_user_init(user) {
      this.active_user = user
      this.adding_user = false
      this.open_modal = true
    },
    async edit_user() {
      this.update_loader()

      try {
        await update_user_permissions_and_managed_users({
          managed_users: this.selected_managed_users,
          permissions: this.selected_permissions,
          id: this.active_user.id
        })

        Object.entries(default_user_data).forEach(([key, val]) => this[key] = val)
        this.adding_user = false
        this.open_modal = false

        this.add_global_error({
          message: this.$translate("user.messages.user_updated"),
          severity: severities.SUCCESS
        })
      } catch (error) {
        this.add_global_error(error)
      }

      this.update_loader(false)
    },
    delete_user_init(user) {
      this.active_user = user
      this.delete_user_modal = true
    },
    async delete_user() {
      this.update_loader()

      try {
        await delete_user({ user_id: this.active_user.id })
        this.set_managed_users(this.managed_users.filter(user_id => user_id !== this.active_user.id))
        await this.fetch_users()

        this.active_user = {}
        this.delete_user_modal = false

        this.add_global_error({
          message: this.$translate("user.messages.user_deleted"),
          severity: severities.SUCCESS
        })
      } catch (error) {
        this.add_global_error(error)
      }

      this.update_loader(false)
    },
    cancel_delete() {
      this.active_user = {}
      this.delete_user_modal = false
    },
    select_managed_user(user) {
      this.selected_managed_users.push(user)
    }
  }
}
</script>

<style lang="scss">
  @use "../../../../../styles/_admin-constants" as *;
  @use "../../../../../styles/admin" as *;
  @use "../../../../../styles/_global-constants" as *;

  .user-management {
    &__add {
      margin-left: auto !important;
    }

    &__actions .md-table-cell-container {
      display: flex;
      flex-direction: row;
    }

    &__users {
      width: 100%;
      max-width: 800px;
      margin: 0 !important;

      .md-content {
        width: 100%;
        overflow: auto;
      }
    }

    $column-width: 360px;

    &__modal {
      display: grid;
      grid-template-columns: minmax($column-width, 1fr) minmax($column-width, 1fr);
      grid-gap: $half-default-size;
      box-sizing: border-box;

      @media (max-width: #{$column-width * 2 + $default-size}) {
        grid-template-columns: 1fr 1fr;
      }

      @media (max-width: $tablet--small) {
        grid-template-columns: 1fr;
      }

      .spinner {
        width: 32px !important;
        height: 32px !important;
      }

      .vue-phone-number-input {
        padding-top: 8px;
        margin: 4px 0 10px;
      }
    }

    &__content {
      position: relative;
      padding: 0 $half-default-size 0 $default-size;
      max-height: 500px;
      overflow: auto;
      vertical-align: top;
      box-sizing: border-box;

      @media (max-width: $tablet--small) {
        padding: $default-size !important;
      }

      @media (max-width: $mobile) {
        padding: $half-default-size !important;
      }

      .md-field {
        position: relative;
        margin-bottom: $half-default-size;

        .spinner-inner {
          transform: scale(0.3) !important;
        }
      }
      
      .md-subheading {
        position: relative;
        margin-top: 0;
      }

      .md-table-content {
        height: 200px !important;
        max-height: 200px !important;
      }

      .md-table {
        .md-checkbox {
          margin-right: 10px;
        }
      }

      &--editing-user {
        .md-table-content {
          height: 400px !important;
          max-height: 400px !important;
        }

        .md-subheading {
          margin-top: 0 !important;
        }
      }

      &--full-size {
        padding-right: 0;
      }
      
      &:last-child {
        padding: 0 $default-size 0 $half-default-size;

        &:after {
          content: "";
          display: block;
          height: 100%;
          width: 1px;
          background: $material-grey--darker;
          position: absolute;
          left: 0;
          top: 0;
        }
      }

      p, div, ul {
        list-style-type: none;
      }

      ul {
        padding: 0;

        li {
          padding-left: $default-size;

          .md-checkbox {
            width: 100%;
            margin: 0 0 $half-default-size;
          }
        }
      }
    }

    &__permissions {
      p {
        border-bottom: 1px solid $material-grey--darker;
      }
    }

    &__email {
      display: inline-block;
      width: calc(100% - 120px);
      margin-right: $half-default-size;
    }

    &__checkbox {
      padding-left: $default-size;
    }

    &__first-element {
      padding-left: 0 !important;
    }

    &__second-title {
      margin-top: $default-size !important;
    }

    &__disabled-content {
      opacity: 0.3;
    }

    &__found-user {
      position: absolute;
      left: 0;
      top: 100%;
      width: 100%;
      padding: $half-default-size;
      background-color: $pure-white;
      border: 1px solid $material-grey--darkest;
      border-top: 0;
      z-index: 2;
      box-shadow: 0 7px 6px -5px rgba(0, 0, 0, 0.3);
      transition: 200ms ease-out;
      cursor: pointer;

      &:hover {
        background-color: $material-grey;
      }

      img {
        display: inline-block;
        width: 40px;
        height: 40px;
        vertical-align: middle;
        object-fit: cover;
      }

      &__info {
        display: inline-block;
        width: calc(100% - 45px);
        text-align: right;
        vertical-align: middle;
      }
    }
  }
</style>
